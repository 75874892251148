import React from "react";
import "./example.css";

const Third = () => {
  return (
    <div id="serviceId" className="third-section">
      <div className="service-header">
        <h1>OUR OFFER INCLUDES</h1>
      </div>
      <div className="service-box">
        <div className="service-item">
          <h3>Free Modern Website</h3>
          <p>
            Sleek and Professional Design
            <br />
            SEO Optimized
            <br />
            Fast Loading Times
            <br />
            Security
          </p>
        </div>
        <div className="service-item">
          <h3>Free AI ChatBot</h3>
          <p>
            Lead Generation
            <br />
            Cutting-Edge Technology
            <br />
            Instant Responses
            <br />
            24/7 Availability
          </p>
        </div>
        <div className="service-item">
          <h3>Free Workflow Automation Consultation</h3>
          <p>
            Efficiency Boost
            <br />
            Cost Savings
            <br />
            Productivity Enhancement
         
          </p>
        </div>
        <div className="service-item">
          <h3>Free CyberSecurity Consultation </h3>
          <p>
            Employee Awareness
            <br />
            Risk Assessment
            <br />
            Phishing Awareness
           
          </p>
        </div>
      </div>
    </div>
  );
};

export default Third;
