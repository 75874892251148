import Reactm, { useState, useEffect, useRef } from "react";
import "./example.css";
import Typewriter from "./Typewriter ";

const Second = () => {
  const [isTextVisible, setIsTextVisible] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const textElement = textRef.current;

      if (textElement) {
        const { top } = textElement.getBoundingClientRect();
        const isVisible = top <= window.innerHeight * 0.75; // Adjust the percentage as needed

        setIsTextVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="about" id="aboutId">
      <div className="inner-about">
        <div className="about-header">
          <h1 ref={textRef}>ABOUT US</h1>
        </div>
        <div className="box-for-text">
          <p className="paragrap">
            ExpandVolume is your premier destination for Google Ads excellence
            and captivating header text creation. We're more than just a digital
            marketing agency; we're your dedicated partner in boosting your
            online presence and increasing your business volume. Led by our
            founder and industry expert, Luka Bagdavadze, our team is committed
            to guiding you through every step of your digital marketing journey.
            From crafting compelling Google Ads campaigns to generating header
            text that grabs attention, we're here to help you succeed. Our
            commitment to your success extends beyond industry-standard
            services. We take the time to understand your unique business needs
            and goals, tailoring our strategies to suit you perfectly.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Second;
